import * as React from "react";
import { graphql, Link } from "gatsby";
import { FaDownload } from "react-icons/fa"

import Layout from "../components/layout";
import Seo from "../components/seo";
import Subscribe from "../components/subscribe";

const ResourceTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || ``;
  const page = data.nodeResource;
  const download =
    page?.relationships?.field_resource_file?.relationships
      ?.field_media_document?.localFile?.publicURL;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={page.title}
        // description={page.description || page.excerpt}
        location={location}
      />
      <article
        className="resource-page"
        itemScope
        itemType="http://schema.org/Article"
      >
        <section className="container py-16">
          <h1>{page.title}</h1>
          {download && (
            <div className="mt-8">
              <p>
                <Link
                  className={`btn btn-green`}
                  to={download}
                  target="_blank"
                  rel="noopener"
                  download
                >
                  Download {page.title} <FaDownload className="ml-3" />
                </Link>
              </p>
              <p>
                <Link
                  className={`btn btn-red`}
                  to={download}
                  target="_blank"
                  rel="noopener"
                >
                  View {page.title}
                </Link>
              </p>
            </div>
          )}
        </section>
        <Subscribe hasTestimonial={false} />
      </article>
    </Layout>
  );
};

export default ResourceTemplate;

export const pageQuery = graphql`
  query ResourceById($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    nodeResource(id: { eq: $id }) {
      title
      relationships {
        field_resource_file {
          relationships {
            field_media_document {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
