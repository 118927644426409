/**
 * Subscribe component
 */

import * as React from "react";

const Subscribe = ({ hasTestimonial }) => {
  return (
    <div
      id="subscribe"
      className={`form-subscribe bg-gray text-white px-8 sm:px-16 lg:px-24 py-8 sm:py-12 lg:py-16 print:hidden ${hasTestimonial ? "-mt-12" : ""
        }`}
    >
      <div className="container mt-9">
        <h2 className="text-center mb-6">Stay up to date!</h2>
        <form
          name="subscribe"
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
          className=""
          action="/thank-you"
        >
          <input type="hidden" name="form-name" value="subscribe" />
          <p className="hidden">
            <label>
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
          </p>
          <div className="flex flex-row justify-center flex-wrap -mx-4">
            <input
              type="text"
              className="form-field"
              placeholder="First name"
              aria-label="First name"
              name="First name"
              required
            />
            <input
              type="text"
              className="form-field"
              placeholder="Last name"
              aria-label="Last name"
              name="Last name"
              required
            />
            <input
              type="email"
              className="form-field"
              placeholder="Email address"
              aria-label="Email address"
              name="Email address"
              required
            />
            <button
              type="submit"
              className="btn btn-green form-btn"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Subscribe;
